import Alpine from "alpinejs";

Alpine.directive("translate", (el, { value: attributeName, expression, modifiers }, { evaluate, evaluateLater, effect }) => {
    const translations = Alpine.store("translations");
    const isEvaluate = modifiers.includes("evaluate");
    const isEvaluateLater = modifiers.includes("evaluatelater");

    let translateText;
    // Handle translations that dynamically update the value
    if (isEvaluateLater) {
        const evaluateTranslateText = evaluateLater(expression);
        effect(() => {
            // Execute callback when the expression value updates
            evaluateTranslateText((text) => {
                translateText = text;
                // Skip translation if the site's language is the default language (English)
                if (translations.skipTranslations) {
                    translations.renderTranslation(el, attributeName, translateText);
                    return;
                }

                // Trigger translation if translations are ready
                if (translations.isReady()) {
                    translations.translate(translateText);
                }
            });
        });
    } else {
        translateText = isEvaluate ? evaluate(expression) : expression;
        // Skip translation if the site's language is the default language (English)
        if (translations.skipTranslations) {
            translations.renderTranslation(el, attributeName, translateText);
            return;
        }
    }
    // Add text to the list for batch translation
    translations.addToTranslateBatchList(translateText);

    // Listen for translation events to update the element's value
    window.addEventListener("translate", (event) => {
        if(event.detail?.translateText == translateText) {
            translations.renderTranslation(el, attributeName, event.detail?.translateText, event.detail?.translatedText);
        }
    });

    // Trigger batch translations if translations are ready
    if (translations.isReady()) {
        translations.translateInBatch();
    }
});