/**
 * Return the site config from light-modules/sanofi-lm-templates/templates/global/prototype/areas/head/V1/head.ftl
 * Empty object is created to allow editor suggestions
 */
const config = {
  editMode: false,
  context: "/",
  site: "",
  userid: "",
  currentLocale: "",
  translationsPath: "",
};
const configID = "site-config";

try {
  const configObj = JSON.parse(document.getElementById(configID).textContent);
  Object.assign(config, configObj);
}
catch (e) {
  console.log(e);
}

export default config;
